import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { motion as Motion } from 'framer-motion';

import { ThemeContext } from '../context/ThemeState';
import { Main } from '../layouts/Main';
import { Songs } from 'components/Tile/Songs';
import { PageBuilder } from '../components/PageBuilder';
import { Footer } from '../components/Footer';

interface I_AboutPage {
    data: any;
}

const AboutPage = ({ data: { contentJson } }: I_AboutPage) => {
    const { changeTheme } = useContext(ThemeContext);
    const [hasScrolled, setHasScrolled] = useState<boolean>(true);
    const { description, keywords, canonical, siteUrl, body, title, imageSocialShare } = contentJson;

    useEffect(() => {
        changeTheme(hasScrolled);
    }, [changeTheme, hasScrolled]);

    useEffect(() => {
        changeTheme(true);
    }, []);

    useEffect(() => {
        const getScroll = () => {
            if (window.pageYOffset > window.innerHeight * 0.75 && hasScrolled) {
                setHasScrolled(false);
            }
            if (window.pageYOffset < window.innerHeight * 0.75 && !hasScrolled) {
                setHasScrolled(true);
            }
        };
        window.addEventListener('scroll', getScroll);

        return () => {
            window.removeEventListener('scroll', getScroll);
        };
    });

    return (
        <Main seoProps={{ title, description, keywords, siteUrl, imageSocialShare, canonical }}>
            <Motion.div
                animate={{
                    opacity: [0, 1],
                    y: [100, 0],
                    transition: { ease: 'easeInOut', duration: 0.75, delay: 0.4 }
                }}
                style={{ opacity: 0 }}
            >
                {/* <Songs /> */}
                <PageBuilder body={body} />
                <Footer />
            </Motion.div>
        </Main>
    );
};

export default AboutPage;

export const data = graphql`
    query {
        contentJson(page: { eq: "about" }) {
            title
            description
            keywords
            siteUrl
            canonical
            body {
                type
                modifiers
                gridWrapper {
                    modifiers
                    spacingTop
                    spacingBottom
                }
                gridWrapperItems {
                    responsive
                }
                cvPoints {
                    companyLocation
                    isSecond
                    jobTitle
                    period
                    text {
                        atom
                        content
                        modifiers
                        spacingTop
                        spacingRight
                        type
                    }
                }
                text {
                    atom
                    content
                    withMark
                    markModifiers
                    modifiers
                    spacingTop
                    spacingBottom
                    type
                    children {
                        atom
                        content
                        modifiers
                        spacingBottom
                        type
                    }
                }
            }
        }
    }
`;
